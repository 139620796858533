<template>
    <div>
        <NavigationBar/>
        <div v-if="loading">
            <!-- Replace with spinner once available -->
          <LoadingSpinner />
        </div>
        <div v-else class="container">
            <h2>Registration and Payment</h2>
            <h5><small>Coverage Year:</small> {{coverageYear}}</h5>
            <h5><small>Location:</small> {{location}}</h5>
            <PaymentTable 
                :membersData="familyMembersData"
                :subtotalAmount="subtotalAmount"
                :totalAmount="totalAmount"
                :donationInputField="donationInputField"
                :setDonationAmount="setDonationAmount"
            />
            <div class="footer-container">
                <div class="footer-inputs" v-if="isInVolunteerFlow || isActive">
                    <div class="input-item">
                        <div class="dropdown-field">
                            <div class="dropdown-label"><b>Payment Method</b></div>
                            <select class="form-select dropdown-input" v-model="selectedPaymentMethod">
                                <option value="N/A">Select an option</option>
                                <option v-for="method in paymentMethods" :key="method.id" :value="method.id">{{method.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="input-item">
                        <div v-if="termsAndConditions" class="form-check">
                            <input class="form-check-input" type="checkbox" id="termsCheck" v-model="acceptedTerms">
                            <label class="form-check-label" for="termsCheck">
                                I agree to the 
                                <button 
                                    class="btn btn-link btn-md" 
                                    style="padding:0; border:0; vertical-align:top;"
                                    data-bs-toggle="modal" 
                                    data-bs-target="#termsModal">terms and conditions
                                </button>
                            </label>
                        </div>
                        <div v-else>
                            The terms and conditions for your Jamatkhana have not been posted yet.
                        </div>
                    </div>
                    <div class="input-item">
                        <button class="btn btn-primary d-flex flex-row float-end disableContinue" :disabled="disableContinue" @click="clickContinue()">Continue</button>
                    </div>
                </div>
                <div v-else>
                    Self registration is not currently active for your Jamatkhana. Please see a Funeral Expense Program volunteer in your local Jamatkhana for further assistance.
                </div>
            </div>
            <br>
            <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error != ''">
                <i class="bi-exclamation-octagon-fill"></i>
                <strong class="mx-2">Error!</strong> {{ error }}
                <br />
            </div>
            <div class="modal" style="filter:drop-shadow(10px 10px 4px #666); width:100%; height:100%; padding: 20pt 20pt" id="termsModal" tabindex="-1" aria-labelledby="termsModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" style="height:100%; width:100%; --bs-modal-width:100%; margin:0;">
                    <div class="modal-content" style="height:100%; width:100%">
                        <div class="modal-header">
                            <h5 class="modal-title" id="termsModalLabel">Terms and Conditions</h5>
                            <button type="button" class="btn-close" @click="closeModal('termsModal')" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" style="height:100%; width:100%">
                            <iframe width="100%" height="100%" :src="termsAndConditions" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { computed, ref } from 'vue'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import NavigationBar from '../components/NavigationBar.vue';
import PaymentTable from '../components/PaymentTable.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue'
import { getMemberInfoFromID, getDollarAndCents, verifyHeadOfHouseHold} from '../utils/MethodUtils.js';
import { Modal } from 'bootstrap';
export default {
    name: "PaymentPage",
    components: {
    NavigationBar,
    PaymentTable,
    LoadingSpinner,
},
    setup() {
        const store = useStore();
        const router = useRouter();
        const error = ref("");

        const coverageYear = ref();
        const familyMembersData = ref([]);
        const location = ref();
        const loading = ref(true);
        const donationAmount = ref(0);
        const donationInputField = ref(0);
        const paymentMethods = ref([]);
        const selectedPaymentMethod = ref("N/A");
        const termsAndConditions = ref();
        const acceptedTerms = ref(false);
        const isActive = ref();
        const isInVolunteerFlow = ref(store.getters.getIsInVolunteerFlow);

        const selectedMembers = computed(() => {
            return familyMembersData.value
                .filter(member => member.includeInPlan)
                .map(member => {
                    return {
                        memberId: member.memberId,
                        firstName: member.firstName,
                        lastName: member.lastName,
                        amount: member.rate.amount
                    }
                });
        })

        const subtotalAmount = computed(() => {
            let subtotal = 0;
            selectedMembers.value.forEach(member => 
                subtotal += member.amount
            )
            return subtotal
        });

        const totalAmount = computed(() => {
            return subtotalAmount.value + donationAmount.value;
        });

        const disableContinue = computed(() => {
            let res = false
            if (
                selectedMembers.value.length === 0 || 
                selectedPaymentMethod.value === "N/A" ||
                !acceptedTerms.value || 
                totalAmount.value === 0 ||
                error.value !== ''
            ) {
               return true;
            }
            selectedMembers.value.forEach(member => {
                if(member.amount == null)
                {
                    res = true;
                }
            });
            return res
        })

        const setDonationAmount = (donation) => {
            if (donation === undefined) {
                donationInputField.value = "$0.00";
                donationAmount.value = 0;
            }
            donationInputField.value = donation;
            roundDonationAmount(donation.replace("$",'').replace(',',''));
        }

        const roundDonationAmount = (donation) => {
            donationAmount.value = Number(Number(donation).toFixed(2));
        }

        const clickContinue = async () => {
            try {
                loading.value=true
                error.value = ""
                // Add global loading call to vue store
                const paymentMethodObject = paymentMethods.value.find(
                    element => element.id === selectedPaymentMethod.value
                )
                if (!paymentMethodObject) {
                    console.log("Invalid payment method")
                    error.value = "Invalid payment method"
                    return
                }

                const paymentObject = {
                    headOfHouseholdMemberId: store.getters.getHouseholdDetails.headOfHousehold.memberId,
                    members: selectedMembers.value,
                    donation: getDollarAndCents(donationAmount.value),
                    total: getDollarAndCents(totalAmount.value),
                    paymentMethod: {
                        id: paymentMethodObject.id,
                        name: paymentMethodObject.name
                    }
                }

                if (selectedPaymentMethod.value === 3) {
                    // Go to ACH details page
                    store.commit('setACHPaymentDetails', paymentObject)
                    router.push({
                        name: 'ACHDetails'
                    })
                    return
                }

                const response = await axios.post("/api/v1/burial/payment", paymentObject)
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    router.push({
                        name:'PaymentStatus', 
                        params: {
                            paymentId: data.paymentId,
                            emailResponseSuccess: data.emailConfirmation.success,
                            emailResponseMessage: data.emailConfirmation.statusMessage,
                        }
                    })
                } else {
                    console.log(statusMessage)
                    error.value = statusMessage
                }
                loading.value = false;
            } catch (err) {
                console.log(err)
                error.value = err
                loading.value = false;
            }
        }

        const getPaymentDetails = async () => {
            try {
                loading.value=true
                const response = await axios.get("/api/v1/burial/rates");
                const { success, statusMessage, planYear } = response.data;
                if (success) {
                    const { data } = response.data;
                    coverageYear.value = planYear;
                    location.value = store.getters.getHouseholdDetails.jamatkhana.name;
                    const storeMembers = await store.getters.getMembers;
                    let membersWithRates = data.map(member => {
                        const {firstName, lastName, relationToHead} = getMemberInfoFromID(member.memberId, storeMembers);
                        member.firstName = firstName;
                        member.lastName = lastName;
                        member.relationToHead = relationToHead;
                        // Include members who haven't paid yet by default and have a valid rate
                        member.includeInPlan = (member.status.id === 1 && member.rate.amount !== null);
                        return member;
                    }).filter(function(member){
                        if(member.relationToHead.id === 88) // individual has passed away
                        {
                            return false; //skip
                        }
                        return true;
                    })
                    familyMembersData.value = membersWithRates;
                } else {
                    console.log(statusMessage)
                    router.push({
                        name:'Error', 
                        params: {
                            errorMsg: 'Payment - ' + statusMessage,
                        }
                    })
                }
                loading.value = false;
            } catch(err) {
                console.error(err)
                 router.push({
                    name:'Error', 
                    params: {
                        errorMsg: 'Payment - ' + err,
                    }
                })
                loading.value = false;
            }
            populatePaymentMethods();
        }

        const getTermsAndConditions = async () => {
            try {
                error.value = "";
                const jkId = store.getters.getHouseholdDetails.jamatkhana.id;
                const response = await axios.get(`/api/v1/admin/instructions-and-tos/${jkId}`)
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    termsAndConditions.value = data.termsAndConditionLink;
                    isActive.value = data.selfRegistrationActive;
                } else {
                    console.log(statusMessage);
                    error.value = statusMessage;
                }
            } catch (err) {
                console.error(err);
                error.value = err;
            }
        }

        const populatePaymentMethods = () => {
            paymentMethods.value = store.getters.getMetadata.paymentMethods;
            if (store.getters.getIsInVolunteerFlow) {
                const updatedpaymentMethods = paymentMethods.value.filter(method => method.id !== 3)
                paymentMethods.value = updatedpaymentMethods;
            }
        }

        const closeModal = (modalId) => {
            Modal.getInstance(document.getElementById(modalId)).hide()
        }

        getPaymentDetails();
        getTermsAndConditions();
        error.value = verifyHeadOfHouseHold(store.getters.getMembers)

        return {
            coverageYear,
            disableContinue,
            familyMembersData,
            loading,
            error,
            location,
            paymentMethods,
            selectedPaymentMethod,
            selectedMembers,
            donationInputField,
            subtotalAmount,
            totalAmount,
            termsAndConditions,
            acceptedTerms,
            isActive,
            isInVolunteerFlow,
            setDonationAmount,
            roundDonationAmount,
            clickContinue,
            closeModal,
        }
    }
}
</script>
<style scoped>
    .footer-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    @media(min-width: 401px) {
        .footer-inputs {
            max-width: 350px;
        }
        .dropdown-field {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .dropdown-label {
            white-space: nowrap;
            padding-right: 12px;
        }
        .dropdown-input {
            min-width: 200px;
        }
        .input-item {
            padding-bottom: 12px;
            display: flex;
            justify-content: flex-end;
        }
    }
    @media(max-width: 400px){
        .footer-inputs {
            width: 100%;
        }
        .dropdown-field {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .dropdown-label {
            padding-right: 12px;
        }
        .input-item {
            padding-bottom: 12px;
        }
    }

</style>
