<template>
    <div>
        <NavigationBar/>
        <div v-if="loading">
          <LoadingSpinner />
        </div>
        <div v-else class="container">
            <div class="ACHBody">
                <h2>ACH Payment Details</h2>
                <form @submit.prevent="handleSubmit" class="form-horizontal">
                    <div class="form-group row">
                        <label for="bankAccountOwnerEdit" class="col-form-label">Bank Account Owner</label>
                        <div>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="bankAccountOwnerEdit" 
                                v-model="form.accountOwner"
                                autocomplete="off" 
                                @blur="checkBankAccountOwner" 
                                required>
                            <div :class="bankAccountOwnerValid ? '':'errorText'">{{bankAccountOwnerValidationMessage}}</div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="ownershipTypeEdit" class="col-form-label">Ownership Type</label>
                        <div>
                            <select class="form-select" id="ownershipTypeEdit" v-model="form.ownershipType" required>
                                <option v-for="ownershipType in ownershipTypes" :key="ownershipType.id" :value="ownershipType" :selected="this.form.ownershipType">{{ownershipType.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="accountTypeEdit" class="col-form-label">Account Type</label>
                        <div>
                            <select class="form-select" id="accountTypeEdit" v-model="form.accountType" required>
                                <option v-for="accountType in accountTypes" :key="accountType.id" :value="accountType" :selected="this.form.accountType">{{accountType.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="routingNumberEdit" class="col-form-label">Routing Number</label>
                        <div>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="routingNumberEdit" 
                                v-model="form.routingNumber"
                                @blur="validateRoutingNumber"
                                :state="routingNumberValid"
                                autocomplete="off" 
                                required>
                            <div :class="routingNumberValid ? 'routingMessage':'errorText routingMessage'">{{routingNumberValidationMessage}}</div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="accountNumberEdit" class="col-form-label">Account Number</label>
                        <div>
                            <input 
                                :type="accountNumberInputType" 
                                class="form-control" 
                                id="accountNumberEdit" 
                                v-model="form.accountNumber" 
                                @focus="clearAndShowAccountNumber" 
                                @blur="maskAccountNumber" 
                                @paste.prevent
                                @copy.prevent
                                @cut.prevent
                                autocomplete="off"
                                required>
                            <div :class="accountNumberValid ? '':'errorText'">{{accountNumberValidationMessage}}</div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="confirmAccountNumberEdit" class="col-form-label">Confirm Account Number</label>
                        <div>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="confirmAccountNumberEdit" 
                                v-model="form.confirmAccountNumber" 
                                @blur="checkAccountNumbers"
                                @paste.prevent
                                @copy.prevent
                                @cut.prevent
                                autocomplete="off"
                                required>
                            <div :class="confirmAccountNumberValid ? '':'errorText'">{{confirmAccountNumberValidationMessage}}</div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="signatureEdit" class="col-form-label">Signature</label>
                        <div class="signature-pad-container">
                            <VueSignaturePad ref="signaturePad" class="signature-pad" :options="{onEnd}" required/>
                        </div>
                        <div class="signature-options">
                            <button class="btn btn-secondary" type="button" @click="clearSignature">Clear Signature</button>
                            <button class="btn btn-link btn-md" type="button" @click="openPdf">Terms and Conditions</button>
                        </div>
                    </div>

                    <div class="table-container">
                        <h4 class="pt-3 text-center">Payment Summary</h4>
                        <div class="table-responsive household-Member-Table pt-2">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="familyMember in paymentObject.members || []" :key="familyMember.id"  @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent>
                                        <td> {{ familyMember.firstName }} {{ familyMember.lastName }}</td>
                                        <td>{{ displayInUSD(familyMember.amount) }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr class="noBorder" v-if="paymentObject.donation">
                                        <td><b>Donation:</b></td>
                                        <td>{{displayInUSD(paymentObject.donation)}}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Total:</b></td>
                                        <td><b>{{displayInUSD(paymentObject.total)}}</b></td>                    
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div class="form-group row submit-section">
                        <button class="btn btn-success" type="submit" :disabled="!isFormValid">Submit</button>
                    </div>
                </form>
                <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error !== ''">
                    <i class="bi-exclamation-octagon-fill"></i>
                    <strong class="mx-2">Error!</strong> {{ error }}
                    <br />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { computed, ref, onMounted } from 'vue';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { VueSignaturePad } from 'vue-signature-pad';
import { displayInUSD } from '../utils/MethodUtils.js';


export default {
    name: "ACHDetailsPage",
    components: {
        NavigationBar,
        LoadingSpinner,
        VueSignaturePad
    },
    props:[],
    setup(props) {
        const store = useStore();
        const loading = ref(false);
        const router = useRouter();
        const ownershipTypes = ref([]);
        const accountTypes = ref([]);
        const routingNumberValidationMessage = ref("");
        const routingNumberValid = ref(false);
        const bankAccountOwnerValidationMessage = ref("");
        const bankAccountOwnerValid = ref(false);
        const accountNumberValidationMessage = ref("");
        const accountNumberValid = ref(false);
        const accountNumberInputType = ref('text');
        const confirmAccountNumberValidationMessage = ref("");
        const confirmAccountNumberValid = ref(false);
        const signatureValid = ref(false);
        const paymentObject = ref(store.getters.getACHPaymentDetails);
        const error = ref("");


        const form = ref({
            accountOwner: "",
            ownershipType: "",
            accountType: "",
            routingNumber: "",
            accountNumber: "",
            confirmAccountNumber: "",
        })

        const signaturePad = ref(null);

        const clearSignature = () => {
            signaturePad.value.clearSignature();
            signatureValid.value = !signaturePad.value.isEmpty();
        };

        const validateRoutingNumber = async () => {
            if (form.value.routingNumber === "") {
                routingNumberValid.value = false;
                routingNumberValidationMessage.value = `Routing number is required`;
                return;
            }
            try {
                const response = await axios.get(`/api/v1/burial/verify_routing_number/${form.value.routingNumber}`);
                if (response.data.message === 'Success') {
                    routingNumberValid.value = true;
                    const bank_name = response.data.bank_name;
                    if (bank_name) {
                        routingNumberValidationMessage.value = `Bank Name: ${response.data.bank_name}`
                    } else {
                        routingNumberValidationMessage.value = ""
                    }
                } else {
                    routingNumberValid.value = false;
                    routingNumberValidationMessage.value = `Invalid Routing Number: ${form.value.routingNumber}`
                }
            } catch (error) {
                routingNumberValid.value = false;
                routingNumberValidationMessage.value = `Invalid Routing Number: ${form.value.routingNumber}`
            }
        };

        const checkBankAccountOwner = () => {
            if (form.value.accountOwner === "") {
                bankAccountOwnerValid.value = false;
                bankAccountOwnerValidationMessage.value = `Bank account owner is required`;
            } else {
                bankAccountOwnerValid.value = true;
                bankAccountOwnerValidationMessage.value = ``;
            }
        }

        const clearAndShowAccountNumber = () => {
            accountNumberInputType.value = 'text';
            form.value.accountNumber = '';
        };

        const maskAccountNumber = () => {
            accountNumberInputType.value = 'password';
            if (form.value.accountNumber === "") {
                accountNumberValid.value = false;
                accountNumberValidationMessage.value = `Account number is required`;
            } else {
                accountNumberValid.value = true;
                accountNumberValidationMessage.value = ``;
            }
        };

        const checkAccountNumbers = () => {
            if (form.value.accountNumber !== form.value.confirmAccountNumber) {
                confirmAccountNumberValid.value = false;
                confirmAccountNumberValidationMessage.value = `Account numbers do not match`;
            } else {
                confirmAccountNumberValid.value = true;
                confirmAccountNumberValidationMessage.value = ``;
            }
        };

        const onEnd = () => {
            signatureValid.value = !signaturePad.value.isEmpty();
        }

        const isFormValid = computed(() => {
            const isAccountOwnerValid = form.value.accountOwner && bankAccountOwnerValid.value;
            const isOwnershipTypeValid = form.value.ownershipType && form.value.ownershipType.id;
            const isAccountTypeValid = form.value.accountType && form.value.accountType.id;
            const isRoutingNumberValid = form.value.routingNumber && routingNumberValid.value;
            const isAccountNumberValid = form.value.accountNumber && accountNumberValid.value;
            const isConfirmAccountNumberValid = form.value.confirmAccountNumber && confirmAccountNumberValid.value;
            const isSignatureValid = signaturePad.value && signatureValid.value;

            return (isAccountOwnerValid 
                && isOwnershipTypeValid
                && isAccountTypeValid
                && isRoutingNumberValid
                && isAccountNumberValid
                && isConfirmAccountNumberValid
                && isSignatureValid);
        });

        const openPdf = () => {
            const pdfUrl = `/api/v1/files/achtermsandconditions.pdf`;
            window.open(pdfUrl, '_blank');
        }

        const handleSubmit = async () => {
            try {
                loading.value=true
                error.value = ""

                const { data } = signaturePad.value.saveSignature();

                const ACHDetails = {
                    accountOwner: form.value.accountOwner,
                    ownershipType: form.value.ownershipType,
                    accountType: form.value.accountType,
                    routingNumber: form.value.routingNumber,
                    accountNumber: form.value.accountNumber,
                    signatureData: data
                }

                paymentObject.value.ACHDetails = ACHDetails;

                if (!paymentObject.value.paymentMethod || paymentObject.value.paymentMethod.id !== 3) {
                    console.log("Invalid payment method")
                    error.value = "Invalid payment method"
                    loading.value=false
                    return
                }
                if (!paymentObject.value.members || paymentObject.value.members.length === 0) {
                    console.log("No covered members defined in transaction")
                    error.value = "No covered members defined in transaction"
                    loading.value=false
                    return
                }
                if (!paymentObject.value.total) {
                    console.log("Total amount not defined")
                    error.value = "Total amount not defined"
                    loading.value=false
                    return
                }
                if (!paymentObject.value.ACHDetails.accountOwner) {
                    console.log("Bank account owner not defined")
                    error.value = "Bank account owner not defined"
                    loading.value=false
                    return
                }
                if (!paymentObject.value.ACHDetails.ownershipType.name || !paymentObject.value.ACHDetails.ownershipType.id) {
                    console.log("Bank account ownership type not defined correctly")
                    error.value = "Bank account ownership type not defined correctly"
                    loading.value=false
                    return
                }
                if (!paymentObject.value.ACHDetails.accountType.name || !paymentObject.value.ACHDetails.accountType.id) {
                    console.log("Bank account type not defined correctly")
                    error.value = "Bank account type not defined correctly"
                    loading.value=false
                    return
                }
                if (!paymentObject.value.ACHDetails.accountNumber) {
                    console.log("Bank account number not defined")
                    error.value = "Bank account number not defined"
                    loading.value=false
                    return
                }
                if (!paymentObject.value.ACHDetails.routingNumber) {
                    console.log("Bank routing number not defined")
                    error.value = "Bank routing number not defined"
                    loading.value=false
                    return
                }
                if (!paymentObject.value.ACHDetails.signatureData) {
                    console.log("Signature not defined")
                    error.value = "Signature not defined"
                    loading.value=false
                    return
                }

                const response = await axios.post("/api/v1/burial/payment", paymentObject.value)
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    router.push({
                        name:'PaymentStatus', 
                        params: {
                            paymentId: data.paymentId,
                            emailResponseSuccess: data.emailConfirmation.success,
                            emailResponseMessage: data.emailConfirmation.statusMessage,
                        }
                    })
                } else {
                    console.log(statusMessage)
                    error.value = statusMessage
                }
                loading.value = false;
            } catch (err) {
                console.log(err)
                error.value = err
                loading.value = false;
            }
        }

        return {
            loading,
            form,
            displayInUSD,
            checkBankAccountOwner,
            bankAccountOwnerValidationMessage,
            bankAccountOwnerValid,
            validateRoutingNumber,
            routingNumberValidationMessage,
            routingNumberValid,
            accountNumberValidationMessage,
            accountNumberValid,
            accountNumberInputType,
            clearAndShowAccountNumber,
            maskAccountNumber,
            confirmAccountNumberValidationMessage,
            confirmAccountNumberValid,
            checkAccountNumbers,
            signaturePad,
            clearSignature,
            handleSubmit,
            accountTypes,
            ownershipTypes,
            isFormValid,
            paymentObject,
            signatureValid,
            onEnd,
            error,
            openPdf
        }
    },
    async created() {
        this.loaded = false
        const metaData = this.$store.getters.getMetadata
        this.ownershipTypes = metaData.bankAccountOwnershipTypes
        this.accountTypes = metaData.bankAccountTypes
        if (!this.ownershipTypes || this.ownershipTypes.length === 0) {
            error.value = "Unable to load bank account ownership types"
            this.loaded = true
            return
        }
        this.form.ownershipType = this.ownershipTypes[0]
        if (!this.accountTypes || this.accountTypes.length === 0) {
            error.value = "Unable to load bank account types"
            this.loaded = true
            return
        }
        this.form.accountType = this.accountTypes[0]
        this.loaded = true
    },
}
</script>
<style scoped>
h2 {
    padding-bottom: 20px;
}
.ACHBody {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.routingMessage {
    color: #777;
}
.errorText {
    color: red;
}
.signature-pad {
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  height: 200px;
}
.signature-options {
    display: flex;
    flex-direction: 1;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
@media(max-width: 400px){
    .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }
    .signature-pad-container {
        width: 100%;
        height: 200px;
    }
    .table-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 0px;
    }
}
@media(min-width: 401px){
    .form-group {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        padding-bottom: 10px;
    }
    .signature-pad-container {
        max-width: 500px;
        height: 200px;
    }
    .table-container {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        padding: 20px 0px;
    }
}
.submit-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px;
}
</style>
