<template>
    <div>
        <NavigationBar/>
        <div v-if="loading">
          <LoadingSpinner />
        </div>
        <div v-else class="container">
            <h2>Payments</h2>
            <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error != ''">
                <i class="bi-exclamation-octagon-fill"></i>
                <strong class="mx-2">Error!</strong> {{ error }}
                <br />
            </div>
            <div class="container" v-for="year in coverageYears" :key="year">
                <h4>{{year}} Coverage Year</h4>
                <div class="container paymentCard" v-for="payment in payments[year]" :key="payment.paymentId">
                    <div class="rowItem">
                        <div>{{payment.paymentId}}</div>
                        <div>{{displayInUSD(payment.total)}}</div>
                    </div>
                    <div class="rowItem">
                        <div>{{payment.paymentMethod.name}}</div>
                        <div>{{payment.paymentStatus.name}}</div>
                    </div>
                    <div class="rowItem">
                        <div class="columnEnd link" v-on:click="clickViewDetails(payment.paymentId)">
                            View Details 
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="coverageYears.length === 0" class="noPayments">
                No payments have been made yet
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { displayInUSD } from '../utils/MethodUtils.js'

export default {
    name: "PaymentsPage",
    components: {
        NavigationBar,
        LoadingSpinner
    },
    setup() {
        const loading = ref(true);
        const error = ref("");
        const payments = ref({});
        const coverageYears = ref([]);

        const router = useRouter();

        const getPayments = async () => {
            try {
                loading.value=true
                const response = await axios.get(`/api/v1/burial/payment`);
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    coverageYears.value = Object.keys(data);
                    payments.value = data;
                    for(let year of coverageYears.value)
                    {
                        payments.value[year].sort((a,b) => (a.paymentId > b.paymentId) ? -1 : ((b.paymentId > a.paymentId) ? 1 : 0));
                    }
                    loading.value = false;
                } else {
                    console.log(statusMessage);
                    error.value = statusMessage;
                    loading.value = false;
                }
            } catch (err) {
                console.error(err);
                error.value = err;
                loading.value = false;
            }
        }

        const clickViewDetails = async (paymentID) => {
            router.push({name:'PaymentStatus', params: {paymentId: paymentID}})
        }

        getPayments();

        return {
            clickViewDetails,
            coverageYears,
            displayInUSD,
            loading,
            error,
            payments
        }
    }
}
</script>
<style scoped>
.first {
    border-top: 1px #ccc solid;
}
.noPayments {
    width: 100%;
    text-align: center;
}
.paymentCard {
    border-bottom: 1px #ccc solid;
    padding-top: 15px;
    padding-bottom: 5px;
}
.rowItem {
    display: flex;
    justify-content: space-between;
}
.columnEnd {
    text-align: end;
    flex: 1;
}
.link {
    color: blue;
    text-decoration: underline;
}
.link:hover {
    cursor: pointer;
}
</style>
